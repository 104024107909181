import React from "react";

import { ConsentGtag } from "./consentGtag";

export const handleClick = (url) => {
    var type

    if (url.includes('tel:+3512')) {
        type = 'Sede'
    } else if (url.includes('tel:+3519')) {
        type = 'Showroom'
    }
    //ConsentGtag()
    if (typeof window !== "undefined") {
        window.gtag('event', 'chamadas', {
            tipo_chamada: type
        })
        console.log('event gtag')
        window.open(url, '_self', 'noreferrer')
    }
}